import React, { useState } from 'react'
import { ThemeProvider, PaletteType } from '@material-ui/core'
import { makeStyles, createStyles, Theme, createMuiTheme } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import Navigation from './components/Navigation'
import Converter from './components/Converter'
import Clock from './components/Clock'
import TimeReference from './components/TimeReference'
import GoogleAds from './components/GoogleAds'
import Resources from './components/Resources'
import TermsOfUse from './components/TermsOfUse'
import PrivacyPolicy from './components/PrivacyPolicy'
import Copyright from './components/Copyright'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(4),
    },
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '6px',
      boxShadow: 'none',
    },
    cardContent: {
      flexGrow: 1,
      padding: '24px',
    },
  }),
)

function App() {
  const [paletteType, setPaletteType] = useState('light')
  const muiTheme = createMuiTheme({
    palette: {
      type: paletteType as PaletteType,
      primary: {
        main: '#4782da',
      },
      background: {
        default: paletteType === 'light' ? '#f7f9fc' : '#1b2635',
        paper: paletteType === 'light' ? '#fff' : '#233044',
      }
    },
  })
  const classes = useStyles()

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <Container component="header" disableGutters={true} maxWidth={false}>
        <Clock />
      </Container>
      <Container component="div" disableGutters={true} maxWidth="lg">
        <Navigation setPaletteType={setPaletteType} />
      </Container>
      <Container component="main" className={classes.main} maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Converter />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <GoogleAds currentPath={window.location.pathname} />
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <TimeReference />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Resources />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <TermsOfUse />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <PrivacyPolicy />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Container component="footer" disableGutters={true} maxWidth={false}>
        <Copyright />
      </Container>
    </ThemeProvider>
  )
}

export default App
