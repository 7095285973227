import React from 'react'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const TimeReference = () => {
  const rows = [
    {
      name: '1 hour',
      inSeconds: 60 * 60
    },
    {
      name: '1 day',
      inSeconds: 60 * 60 * 24
    },
    {
      name: '1 week',
      inSeconds: 60 * 60 * 24 * 7
    },
  ]
  return (
    <React.Fragment>
      <Typography variant="h6" component="h2">
        Time Reference
      </Typography>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Time</TableCell>
            <TableCell align="right">In Seconds</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">
                {row.inSeconds} seconds
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

export default TimeReference