import React, { useState, useEffect } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import CachedIcon from '@material-ui/icons/Cached'

import ConverterResult from './ConverterResult'
import { TimeZone, formatTime } from '../utils/DateUtils'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    border: 'none',
    boxShadow: 'none',
    backgroundColor: theme.palette.background.default,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  loadButton: {
    textAlign: 'right',
  }
}));

interface TimestampState {
  input?: string
  timestamp: number
}

interface IsoUTCState {
  input?: string
  timestamp: number
}

interface DateUTCState {
  dateUTC?: Date
  dateUTCTime: string
  dateUTCSecond?: string
  timestamp: number
}

interface DateLocalState {
  dateLocal?: Date
  dateLocalTime: string
  dateLocalSecond?: string
  timestamp: number
}

const Converter: React.FC = () => {
  const classes = useStyles()
  let timestamp = new Date().getTime()

  const [timestampState, setTimestampState] = useState<TimestampState>({
    input: (Math.floor(timestamp/1000)).toString(),
    timestamp: timestamp,
  })
  const [isoUTCState, setIsoUTCState] = useState<IsoUTCState>({
    input: new Date(timestamp).toISOString(),
    timestamp: timestamp,
  })
  const [dateUTCState, setDateUTCState] = useState<DateUTCState>({
    dateUTC: new Date(timestamp + new Date().getTimezoneOffset() * 60 * 1000),
    dateUTCTime: formatTime(new Date(timestamp), TimeZone.UTC),
    dateUTCSecond: new Date(timestamp).getSeconds().toString(),
    timestamp: timestamp,
  })
  const [dateLocalState, setDateLocalState] = useState<DateLocalState>({
    dateLocal: new Date(timestamp),
    dateLocalTime: formatTime(new Date(timestamp), undefined),
    dateLocalSecond: new Date(timestamp).getSeconds().toString(),
    timestamp: timestamp,
  })

  const loadCurrentTime = () => {
    timestamp = new Date().getTime()
    setTimestampState({
      input: (Math.floor(timestamp/1000)).toString(),
      timestamp: timestamp,
    })
    setIsoUTCState({
      input: new Date(timestamp).toISOString(),
      timestamp: timestamp,
    })
    setDateUTCState({
      dateUTC: new Date(timestamp + new Date().getTimezoneOffset() * 60 * 1000),
      dateUTCTime: formatTime(new Date(timestamp), TimeZone.UTC),
      dateUTCSecond: new Date(timestamp).getSeconds().toString(),
      timestamp: timestamp,
    })
    // bugfix for updating type=time field 
    const dateUTCTimeElm = document.getElementById('dateUTCTime') as any;
    if (dateUTCTimeElm && dateUTCTimeElm.value) {
      dateUTCTimeElm.value = formatTime(new Date(timestamp), TimeZone.UTC);
    }
    setDateLocalState({
      dateLocal: new Date(timestamp),
      dateLocalTime: formatTime(new Date(timestamp), undefined),
      dateLocalSecond: new Date(timestamp).getSeconds().toString(),
      timestamp: timestamp,
    })
    // bugfix for updating type=time field 
    const dateLocalTimeElm = document.getElementById('dateLocalTime') as any;
    if (dateLocalTimeElm && dateLocalTimeElm.value) {
      dateLocalTimeElm.value = formatTime(new Date(timestamp), undefined);
    }
  }

  const handleTimestampChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let timestamp = parseInt(event.target.value)
    if (timestamp === undefined || timestamp === null || Number.isNaN(timestamp)) {
      timestamp = -1
    }
    setTimestampState({
      input: event.target.value,
      timestamp,
    })
  }

  const handleIsoUTCChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let timestamp = new Date(event.target.value).getTime()
    if (timestamp === undefined || timestamp === null || Number.isNaN(timestamp)) {
      timestamp = -1
    }
    setIsoUTCState({
      input: event.target.value,
      timestamp,
    })
  }

  const handleDateUTCChange = (prop: keyof DateUTCState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let timestamp = 0
    if (prop === 'dateUTCTime') {
      if (dateUTCState.dateUTC) {
        const hour = parseInt(event.target.value.split(':')[0])
        const minute = parseInt(event.target.value.split(':')[1])
        const second = parseInt(dateUTCState.dateUTCSecond ? dateUTCState.dateUTCSecond : '0')
        timestamp = Date.UTC(dateUTCState.dateUTC.getFullYear(), dateUTCState.dateUTC.getMonth(), dateUTCState.dateUTC.getDate(), hour, minute, second)
        setDateUTCState({
          ...dateUTCState,
          ...{
            dateUTCTime: formatTime(new Date(timestamp), TimeZone.UTC),
            timestamp : timestamp,
          },
        })
      }
    }
    else if (prop === 'dateUTCSecond') {
      let second = 0;
      if (dateUTCState.dateUTC) {
        const hour = parseInt(dateUTCState.dateUTCTime.split(':')[0])
        const minute = parseInt(dateUTCState.dateUTCTime.split(':')[1])
        second = parseInt(event.target.value)
        if (!second || second < 0) {
          second = 0
        } else if (second > 59) {
          second = 59
        }
        timestamp = Date.UTC(dateUTCState.dateUTC.getFullYear(), dateUTCState.dateUTC.getMonth(), dateUTCState.dateUTC.getDate(), hour, minute, second)
        if (!timestamp) {
          timestamp = -1
        }
      }
      setDateUTCState({
        ...dateUTCState,
        ...{
          dateUTCSecond: second.toString(),
          timestamp : timestamp,
        },
      })
    }
  }

  const handleDateLocalChange = (prop: keyof DateLocalState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let timestamp = 0
    if (prop === 'dateLocalTime') {
      if (dateLocalState.dateLocal) {
        const hour = parseInt(event.target.value.split(':')[0])
        const minute = parseInt(event.target.value.split(':')[1])
        const second = parseInt(dateLocalState.dateLocalSecond ? dateLocalState.dateLocalSecond : '0')
        timestamp = new Date(dateLocalState.dateLocal.getFullYear(), dateLocalState.dateLocal.getMonth(), dateLocalState.dateLocal.getDate(), hour, minute, second).getTime()
        setDateLocalState({
          ...dateLocalState,
          ...{
            dateLocalTime: formatTime(new Date(timestamp), undefined),
            timestamp : timestamp,
          },
        })
      }
    }
    else if (prop === 'dateLocalSecond') {
      let second = 0;
      if (dateLocalState.dateLocal) {
        const hour = parseInt(dateLocalState.dateLocalTime.split(':')[0])
        const minute = parseInt(dateLocalState.dateLocalTime.split(':')[1])
        second = parseInt(event.target.value)
        if (!second || second < 0) {
          second = 0
        } else if (second > 59) {
          second = 59
        }
        timestamp = new Date(dateLocalState.dateLocal.getFullYear(), dateLocalState.dateLocal.getMonth(), dateLocalState.dateLocal.getDate(), hour, minute, second).getTime()
        if (!timestamp) {
          timestamp = -1
        }
      }
      setDateLocalState({
        ...dateLocalState,
        ...{
          dateLocalSecond : second.toString(),
          timestamp : timestamp,
        },
      })
    }
  }

  const handleDateUTCDateChange = (date: Date | null) => {
    if (date) {
      const hour = parseInt(dateUTCState.dateUTCTime.split(':')[0])
      const minute = parseInt(dateUTCState.dateUTCTime.split(':')[1])
      const second = parseInt(dateUTCState.dateUTCSecond ? dateUTCState.dateUTCSecond : '0')
      const timestamp = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute, second)
      setDateUTCState({
        ...dateUTCState,
        ...{
          dateUTC : date,
          dateUTCSecond : second.toString(),
          timestamp : timestamp,
        },
      })
    }
  }

  const handleDateLocalDateChange = (date: Date | null) => {
    if (date) {
      const hour = parseInt(dateLocalState.dateLocalTime.split(':')[0])
      const minute = parseInt(dateLocalState.dateLocalTime.split(':')[1])
      const second = parseInt(dateLocalState.dateLocalSecond ? dateLocalState.dateLocalSecond : '0')
      const timestamp = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour, minute, second).getTime()
      setDateLocalState({
        ...dateLocalState,
        ...{
          dateLocal : date,
          dateLocalSecond : second.toString(),
          timestamp : timestamp,
        },
      })
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item sm={12} className={classes.loadButton}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<CachedIcon />}
          onClick={() => { loadCurrentTime() }}
        >
          Load Current Time
        </Button>
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography variant="h6" component="h2">
          Unix Timestamp
        </Typography>
        <TextField
          id="timestamp"
          label="Timestamp"
          type="number"
          placeholder="0"
          helperText="supportting format: Unix timestamps in seconds or milliseconds"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          value={timestampState.input}
          onChange={handleTimestampChange}
        />
        <Paper elevation={1} className={classes.paper}>
          <ConverterResult timestamp={timestampState.timestamp} />
        </Paper>
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography variant="h6" component="h2">
          ISO 8601
        </Typography>
        <TextField
          id="isoUTC"
          label="ISO 8601"
          placeholder="1970-01-01T00:00:00.000Z"
          helperText="supporting format: 1970-01-01T00:00:00.000Z or 1970-01-01T00:00:00.000+00:00"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          value={isoUTCState.input}
          onChange={handleIsoUTCChange}
        />
        <Paper elevation={1} className={classes.paper}>
          <ConverterResult timestamp={isoUTCState.timestamp} />
        </Paper>
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography variant="h6" component="h2">
          UTC
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                format="yyyy-MM-dd"
                margin="normal"
                id="dateUTCDate"
                label="Date"
                value={dateUTCState.dateUTC}
                helperText=" "
                onChange={handleDateUTCDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                variant="inline"
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                fullWidth
                id="dateUTCTime"
                label="Time"
                type="time"
                defaultValue={dateUTCState.dateUTCTime}
                margin="normal"
                className={classes.textField}
                onChange={handleDateUTCChange('dateUTCTime')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                fullWidth
                id="dateUTCSecond"
                label="Second"
                type="number"
                margin="normal"
                InputProps={{
                  inputProps: { 
                      max: 59, min: 0 
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.textField}
                value={dateUTCState.dateUTCSecond}
                onChange={handleDateUTCChange('dateUTCSecond')}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <Paper elevation={1} className={classes.paper}>
          <ConverterResult timestamp={dateUTCState.timestamp} />
        </Paper>
      </Grid>
      <Grid item sm={12} md={6}>
        <Typography variant="h6" component="h2">
          Your Time Zone
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <KeyboardDatePicker
                fullWidth
                disableToolbar
                format="yyyy-MM-dd"
                margin="normal"
                id="dateLocalDate"
                label="Date"
                value={dateLocalState.dateLocal}
                helperText=" "
                onChange={handleDateLocalDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                variant="inline"
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                fullWidth
                id="dateLocalTime"
                label="Time"
                type="time"
                defaultValue={dateLocalState.dateLocalTime}
                margin="normal"
                className={classes.textField}
                onChange={handleDateLocalChange('dateLocalTime')}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item sm={4}>
              <TextField
                fullWidth
                id="dateLocalSecond"
                label="Second"
                type="number"
                margin="normal"
                InputProps={{
                  inputProps: { 
                      max: 59, min: 0 
                  }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.textField}
                value={dateLocalState.dateLocalSecond}
                onChange={handleDateLocalChange('dateLocalSecond')}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <Paper elevation={1} className={classes.paper}>
          <ConverterResult timestamp={dateLocalState.timestamp} />
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Converter
