import React from 'react'
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.info.main,
    },
  }),
)

const Resources: React.FC = () => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <Typography variant="h5" component="h2" gutterBottom>
        Resources
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Typography variant="body1" component="h3" gutterBottom>
            <Link href="https://en.wikipedia.org/wiki/Unix_time" className={classes.link} target="_blank" rel="noopener">
              Unix time - Wikipedia
            </Link>
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p" gutterBottom>
            Unix time (POSIX time or Epoch time) is a system for describing instants in time,
            defined as the number of seconds that have elapsed
            since 00:00:00 Coordinated Universal Time (UTC), Thursday, 1 January 1970, not counting leap seconds.
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="body1" component="h3" gutterBottom>
            <Link href="https://en.wikipedia.org/wiki/Greenwich_Mean_Time" className={classes.link} target="_blank" rel="noopener">
              GMT - Wikipedia
            </Link>
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p" gutterBottom>
            Greenwich Mean Time (GMT) is the mean solar time at the Royal Observatory in Greenwich,
            London. Utc was formerly used as the international civil time standard,
            now superseded in that function by Coordinated Universal Time (UTC).
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="body1" component="h3" gutterBottom>
            <Link href="https://en.wikipedia.org/wiki/ISO_8601" className={classes.link} target="_blank" rel="noopener">
              ISO 8601 - Wikipedia
              </Link>
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p" gutterBottom>
            ISO 8601 is an international standard covering the exchange of date- and time-related data.
            It was issued by the International Organization for Standardization (ISO) and was first published in 1988.
            The purpose of this standard is to provide an unambiguous and well-defined method of representing dates and times,
            so as to avoid misinterpretation of numeric representations of dates and times,
            particularly when data is transferred between countries with different conventions for writing numeric dates and times.
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="body1" component="h3" gutterBottom>
            <Link href="https://en.wikipedia.org/wiki/Coordinated_Universal_Time" className={classes.link} target="_blank" rel="noopener">
              UTC - Wikipedia
            </Link>
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p" gutterBottom>
            Coordinated Universal Time (French: Temps universel coordonné), abbreviated to UTC or CUT,
            is the primary time standard by which the world regulates clocks and time.
            It is within about 1 second of mean solar time at 0° longitude;[1] it does not observe daylight saving time.
            It is one of several closely related successors to Greenwich Mean Time (Utc).
            For most purposes, UTC is considered interchangeable with Utc, but Utc is no longer precisely defined
            by the scientific community.
            </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default Resources
