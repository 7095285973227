import React, { useState, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { TimeZone, formatDateTime } from '../utils/DateUtils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(1),
    },
  }),
)

interface Props {
  timestamp: number
}

interface State {
  timestampInSeconds: string
  timestampInMilli: string
  isoUTC: string
  dateUTC: string
  dateLocal: string
}

const ConverterResult: React.FC<Props> = (props) => {
  const classes = useStyles()
  let { timestamp } = props
  const [values, setState] = useState<State>({
    timestampInSeconds: '',
    timestampInMilli: '',
    isoUTC: '',
    dateUTC: '',
    dateLocal: '',
  })

  useEffect(() => {
    let isError = false
    if (!timestamp || timestamp == null) {
      timestamp = 0
    } else if (timestamp === -1) {
      isError = true
    } else if (timestamp < Math.pow(10,11)) {
      timestamp = timestamp * 1000
    } else if (timestamp < Math.pow(10,14)) {
      timestamp = timestamp
    } else {
      isError = true
    }
    try {
      const date = new Date(timestamp)
      setState({
        timestampInSeconds: isError ? 'N/A' : Math.floor(timestamp/1000).toString(),
        timestampInMilli: isError ? 'N/A' : timestamp.toString(),
        isoUTC: isError ? 'N/A' : date.toISOString(),
        dateUTC: isError ? 'N/A' : formatDateTime(date, TimeZone.UTC),
        dateLocal: isError ? 'N/A' : formatDateTime(date, TimeZone.Local),
      })
    } catch (error) {
      console.log(error.message)
    }
  }, [timestamp])

  return (
    <Table size="small" className={classes.root}>
      <TableBody>
        <TableRow hover>
          <TableCell scope="row">
            Timestamp
          </TableCell>
          <TableCell align="right">
            {values.timestampInSeconds}
          </TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell scope="row">
            Timestamp in milliseconds
          </TableCell>
          <TableCell align="right">
            {values.timestampInMilli}
          </TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell scope="row">
            ISO 8601
          </TableCell>
          <TableCell align="right">
            {values.isoUTC}
          </TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell scope="row">
            Date Time (UTC)
          </TableCell>
          <TableCell align="right">
            {values.dateUTC}
          </TableCell>
        </TableRow>
        <TableRow hover>
          <TableCell scope="row">
            Date Time (your time zone)
          </TableCell>
          <TableCell align="right">
            {values.dateLocal}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default ConverterResult