import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}))

const Copyright: React.FC = () => {
  const classes = useStyles()
  return (
    <Box p={6} className={classes.root}>
      <Typography variant="body2" align="center" color="textSecondary">
        Copyright &copy; {new Date().getFullYear()} timestamp-converter.com. All rights reserved.
      </Typography>
    </Box>
  )
}

export default Copyright
