export enum TimeZone {
  UTC = 'UTC',
  Local = 'Local',
}

/**
 * get users timezone
 * @returns
 * 'Eastern Standard Time' or 'EST' depending on browser
 */
export const getTimeZone = (() => {
  let timezone = ''
  const timeZoneMatch = new Date().toString().match(/\(([A-Za-z\s].*)\)/)
  if (timeZoneMatch && timeZoneMatch.length > 0) {
    timezone = timeZoneMatch[1]
  }
  return timezone
})

/**
 * format date time format
 * @param timestamp
 * @param timeZone
 * @returns
 * 'Feb 15, 2021, 3:03:33 PM'
 */
export const formatDateTime = ((date: Date, timeZone: TimeZone) => {
  const userLang = navigator.language;
  let dateTimeFormat = null;
  if (timeZone == TimeZone.UTC) {
    dateTimeFormat = new Intl.DateTimeFormat(userLang, {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      timeZone: 'UTC',
    })
  } else if (timeZone == TimeZone.Local) {
    dateTimeFormat = new Intl.DateTimeFormat(userLang, {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
    })
  } else {
    dateTimeFormat = new Intl.DateTimeFormat(userLang, {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric',
      timeZone: timeZone,
    })
  }
  return dateTimeFormat.format(date)
})

/**
 * format time
 * @param date
 * @param timeZone
 * @returns
 * '03:03'
 */
export const formatTime = (date: Date, timeZone: TimeZone | undefined) => {
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric', minute: 'numeric', hour12: false, 
    timeZone: timeZone,
  }).format(date).replace('24','00')
}
