import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { TimeZone, getTimeZone, formatDateTime } from '../utils/DateUtils'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    color: theme.palette.common.white,
    backgroundColor: '#233044',
  },
}))

const Clock: React.FC = () => {
  const [date, setDate] = useState(new Date())
  const classes = useStyles()
  const timezone = getTimeZone()

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date())
    }, 1000)
    return () => clearInterval(interval);
  }, [date])

  return (
    <Box p={5} className={classes.root}>
      <Typography variant="h6" component="h2" gutterBottom>
        Current Unix Timesamp
      </Typography>
      <Typography variant="h4">
        {Math.floor(date.getTime()/1000)}
      </Typography>
      <Typography variant="h6">
        {formatDateTime(date, TimeZone.Local)}
      </Typography>
      <Typography variant="body1">
        ({timezone})
      </Typography>
    </Box>
  )
}

export default Clock