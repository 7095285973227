import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import WbSunny from '@material-ui/icons/WbSunny'
import Brightness2 from '@material-ui/icons/Brightness2'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    toolbar: {
      flexWrap: 'wrap',
      backgroundColor: 'transparent',
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    icon: {
      marginRight: theme.spacing(2),
    },
  }),
)

interface Props {
  setPaletteType: React.Dispatch<React.SetStateAction<string>>
}

const Navigation: React.FC<Props> = ({setPaletteType}) => {
  const [type, setType] = useState('light');
  const classes = useStyles()
  const handleChange = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
    if (value) {
      setType(value)
      setPaletteType(value)
    }
  }
  return (
    <AppBar component="nav" position="relative" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
          {/* Timestamp Converter */}
        </Typography>
        <ToggleButtonGroup
          value={type}
          size="small"
          exclusive
          onChange={handleChange}
          aria-label="text alignment"
        >
          <ToggleButton value="light" aria-label="left aligned">
            <WbSunny fontSize="small" />
          </ToggleButton>
          <ToggleButton value="dark" aria-label="centered">
            <Brightness2 fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </Toolbar>
    </AppBar>
  )
}

export default Navigation
