import React from 'react'
import Typography from '@material-ui/core/Typography'

const TermsOfUse = () => {
  return (
    <div>
      <Typography variant="h5" component="h2" gutterBottom>Terms of Use</Typography>

      <p>Version 1.0</p>

      <p>The Timestamp Converter website located at https://www.timestamp-converter.com/ is a copyrighted work belonging to timestamp-converter.com. Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features.</p>

      <p>All such additional terms, guidelines, and rules are incorporated by reference into these Terms.</p>

      <Typography variant="h6" component="h3" gutterBottom>Access to the Site</Typography>

      <p><strong>Certain Restrictions.</strong> The rights approved to you in these Terms are subject to the following restrictions: (a) you shall not sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site; (b) you shall not change, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website; and (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms.  All copyright and other proprietary notices on the Site must be retained on all copies thereof.</p>

      <p>Timestamp Converter reserves the right to change, suspend, or cease the Site with or without notice to you. You approved that Timestamp Converter will not be held liable to you or any third-party for any change, interruption, or termination of the Site or any part.</p>

      <p><strong>No Support or Maintenance.</strong> You agree that Timestamp Converter will have no obligation to provide you with any support in connection with the Site.</p>

      <Typography variant="h6" component="h3" gutterBottom>Third-Party Links & Ads; Other Users</Typography>

      <p><strong>Third-Party Links & Ads.</strong> The Site may contain links to third-party websites and services, and/or display advertisements for third-parties.  Such Third-Party Links & Ads are not under the control of Timestamp Converter, and Timestamp Converter is not responsible for any Third-Party Links & Ads.  Timestamp Converter provides access to these Third-Party Links & Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links & Ads.  You use all Third-Party Links & Ads at your own risk, and should apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links & Ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices.</p>

      <p><strong>Other Users.</strong> Each Site user is solely responsible for any and all of its own User Content. Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others. You agree that Timestamp Converter will not be responsible for any loss or damage incurred as the result of any such interactions. If there is a dispute between you and any Site user, we are under no obligation to become involved.</p>

      <Typography variant="h6" component="h3" gutterBottom>Disclaimers</Typography>

      <Typography variant="body2" color="textPrimary" component="p" gutterBottom>
        This converter site is just for proving information.
        Do not fully rely on this information, always double-check provided information.
        Here are some of the problems and cases that you can face:
      </Typography>
      <Typography variant="body2" color="textPrimary" component="ul" gutterBottom>
        <li>This tools use the date-settings from your computer. If the time or timezone settings on your computer are incorrect, this site can display incorrect informations.</li>
        <li>This tools may have also problems with some dates, dates far in the future or far in the past dates.</li>
      </Typography>
      <Typography variant="body2" color="textPrimary" component="p" gutterBottom>
        The information provided on this website is for general information purposes only.
        There is no warranties of any kind information, about the completeness, accuracy, reliability, suitability
        or availability with respect to the website or the information or related graphics on the website for any purpose.
        Any reliance from you on such information is therefore at your own risk.
      </Typography>
      <Typography variant="body2" color="textPrimary" component="p" gutterBottom>
        We will not be liable for any loss or damage including without limitation, indirect or consequential loss or damage,
        or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
      </Typography>
      <Typography variant="body2" color="textPrimary" component="p" gutterBottom>
        The effort is always made to keep this website up and running smoothly.
        However, <strong>timestamp-converter.com</strong> takes no responsibility for, and will not be liable for, the website being unavailable.
      </Typography>
    </div>
  )
}

export default TermsOfUse